import React, { Fragment, useContext, useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import CustomButton from "../customs/CustomButton";
import CustomText from "../customs/CustomText";
import { Link, useHistory, useLocation } from "react-router-dom";
import CustomDialog from "../customs/CustomDialog";
import queryString from "query-string";
import { fetchDefaultWithCredential, isMobile } from "../../utils";
import CustomCheckbox from "../customs/CustomCheckbox";
import { useCertification } from "../Pay";
import {
  GlobalContext,
  SnackbarContext,
} from "../../globals/components/ComponentsWrapper";
import { useUser } from "../../globals/user";
import SignPng from "../../pngs/sign.png";

interface SigninFormProps {
  email: string;
  password: string;
  name: string;
  phone: string;
  confirm_password: string;
  signin_persistence: boolean;
}

interface SigninFeedbackProps extends SigninFormProps {
  general?: string;
}

type StatusProps =
  | "default"
  | "forgot"
  | "completed"
  | "email"
  | "password"
  | "password2";

export function SigninForm() {
  const [form, setForm] = useState<SigninFormProps>({
    email: "",
    password: "",
    name: "",
    phone: "",
    confirm_password: "",
    signin_persistence: false,
  });
  const { redirect } = useContext(SigninContext);
  const [feedback, setFeedback] = useState<SigninFeedbackProps>();
  const history = useHistory();
  const { refreshUser } = useContext(GlobalContext);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    return fetchDefaultWithCredential(`/auth/login`, "POST", form).then(
      (res) => {
        if (!res.ok) {
          return res.json().then((data) => setFeedback(data));
        } else {
          return res.text().then((authToken) => {
            window.localStorage.setItem("credential", authToken);
            refreshUser().then(() => {
              if (redirect) {
                history.replace(redirect);
              }
            });
          });
        }
      }
    );
  };

  const handleChange = (e: React.FormEvent<HTMLFormElement>) => {
    const target = e.target as HTMLInputElement;
    setForm({
      ...form,
      [target.name]: target.value,
    });
    setFeedback(undefined);
  };

  return (
    <Fragment>
      <div
        style={{
          fontSize: 25,
          fontWeight: 800,
          textAlign: "center",
        }}
      >
        필로어스 멤버 로그인
      </div>
      <div className="text-align-center" style={{ marginTop: 15 }}>
        어서오세요.
        <br />
        필로어스는 당신을 기다리고 있었어요!
      </div>
      <form onChange={handleChange} onSubmit={handleSubmit}>
        <div style={{ marginTop: 30 }}>
          <CustomText width="100%" type="email" label="이메일" name="email" />
        </div>
        <div style={{ marginTop: 15 }}>
          <CustomText
            width="100%"
            type="password"
            label="비밀번호"
            name="password"
          />
        </div>
        {feedback && (
          <div
            style={{
              marginTop: 15,
              fontSize: 14,
              color: "#FF4E4E",
            }}
          >
            {feedback.general}
          </div>
        )}
        <div
          style={{
            marginTop: 15,
          }}
        >
          <Button
            style={{
              backgroundColor: "#6939CD",
              padding: 15,
              borderRadius: 10,
              color: "white",
              fontWeight: 700,
            }}
            fullWidth
            type="submit"
          >
            LOGIN
          </Button>
        </div>
        <div
          style={{
            marginTop: 15,
            backgroundColor: "#D4D0FE",
            borderRadius: 10,
            color: "#7000FF",
            textAlign: "center",
            padding: 15,
            fontWeight: 700,
            cursor: "pointer",
          }}
          onClick={() => history.push("/signup")}
        >
          회원가입 하고{" "}
          <span
            style={{
              color: "black",
            }}
          >
            5,000원
          </span>{" "}
          쿠폰 받기
        </div>
        <div
          style={{
            marginTop: 15,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: 12,
          }}
        >
          <div style={{ display: "flex" }}>
            <div
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => history.push("/signin?status=email")}
            >
              이메일 찾기
            </div>
            <div
              style={{
                marginLeft: 10,
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => history.push("/signin?status=password")}
            >
              비밀번호 찾기
            </div>
          </div>
          <div>
            <CustomCheckbox
              checked={form.signin_persistence}
              handleChange={(checked) => {
                form.signin_persistence = checked;
                setForm({ ...form });
              }}
              border={false}
              labelPlacement="start"
            >
              <div style={{ marginRight: 10, fontSize: 14 }}>로그인 유지</div>
            </CustomCheckbox>
          </div>
        </div>
      </form>
    </Fragment>
  );
}

interface EmailFindForm {
  name: string;
  phone: string;
}

function EmailFindComponent() {
  const { handleCertify } = useCertification();
  const location = useLocation();
  const { showWarningSnackbar } = useContext(SnackbarContext);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const history = useHistory();

  useEffect(() => {
    if (!location.state) return;
    const { mobile_verified } = location.state as any;
    if (!mobile_verified) {
      showWarningSnackbar("인증에 실패하셨습니다");
    } else {
      const { phone, name } = location.state as any;
      fetchDefaultWithCredential("/user/email/find", "POST", {
        name,
        phone,
      }).then((res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            showWarningSnackbar(error);
          });
        }
        return res.json().then(({ name, email }) => {
          setName(name);
          setEmail(email);
        });
      });
    }
  }, [location, showWarningSnackbar]);

  const findEmail = () => {
    handleCertify(
      "email",
      (data) => {
        fetchDefaultWithCredential("/user/email/find", "POST", data).then(
          (res) => {
            if (!res.ok) {
              return res.json().then(({ error }) => {
                showWarningSnackbar(error);
              });
            }
            return res.json().then(({ name, email }) => {
              setName(name);
              setEmail(email);
            });
          }
        );
      },
      () => {
        showWarningSnackbar("인증에 실패하셨습니다");
      }
    );
  };

  if (Boolean(email) && Boolean(name)) {
    return (
      <>
        <div
          style={{
            fontSize: 25,
            fontWeight: 800,
            textAlign: "center",
          }}
        >
          {name} 님의 이메일은
          <span
            style={{
              color: "#6939CD",
            }}
          >
            {email}
          </span>
          입니다.
        </div>
        <div
          style={{
            marginTop: 30,
          }}
        >
          <Button
            style={{
              backgroundColor: "#6939CD",
              padding: 15,
              borderRadius: 10,
              color: "white",
              fontWeight: 700,
            }}
            fullWidth
            onClick={() => {
              setEmail("");
              setName("");
              history.push("/signin");
            }}
          >
            로그인 화면 돌아가기
          </Button>
        </div>
      </>
    );
  }

  return (
    <Fragment>
      <div
        style={{
          fontSize: 25,
          fontWeight: 800,
          textAlign: "center",
        }}
      >
        아이디 찾기
      </div>
      <div className="text-align-center" style={{ marginTop: 15 }}>
        필로어스에 가입한 계정정보를 입력해주세요.
      </div>
      <div
        style={{
          marginTop: 30,
        }}
      >
        <Button
          style={{
            backgroundColor: "#6939CD",
            padding: 15,
            borderRadius: 10,
            color: "white",
            fontWeight: 700,
          }}
          fullWidth
          onClick={findEmail}
        >
          인증 요청하기
        </Button>
      </div>
    </Fragment>
  );
}

interface PasswordFindForm extends EmailFindForm {
  email: string;
}

interface Password2FindForm {
  password: string;
  confirm_password: string;
}

function Password2Component() {
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const [form, setForm] = useState<Password2FindForm>({
    password: "",
    confirm_password: "",
  });
  const [feedback, setFeedback] = useState<Password2FindForm>();
  const [passwordConfirmed, setPasswordConfirmed] = useState<boolean>(false);

  const handleChange = (e: React.FormEvent<HTMLFormElement>) => {
    const target = e.target as HTMLInputElement;
    setForm({
      ...form,
      [target.name]: target.value,
    });
    setFeedback(undefined);
  };

  const changePassword = () => {
    fetchDefaultWithCredential(`/user/password/reset`, "POST", {
      ...form,
      token: parsed.token,
    }).then((res) => {
      if (!res.ok) {
        return res.json().then(setFeedback);
      }
      return res.json().then(() => {
        setPasswordConfirmed(true);
      });
    });
  };

  return (
    <Fragment>
      <form onBlur={handleChange}>
        <Grid container direction="column" alignItems="center">
          <p className="font-header">새로운 비밀번호를 입력해주세요.</p>
          <div style={{ marginTop: "50px", width: "360px" }}>
            <div>
              <CustomText
                width="100%"
                type="password"
                label="새로운 비밀번호"
                name="password"
                placeholder="영문 대/소문자,숫자,특수문자 포함 8~16자리"
                feedback={(() => {
                  if (!feedback) return undefined;
                  if (feedback.password !== "") {
                    return {
                      status: false,
                      message: feedback.password,
                    };
                  }
                  return { status: true };
                })()}
              />
            </div>
            <div className="mt-30">
              <CustomText
                width="100%"
                type="password"
                label="새로운 비밀번호 확인"
                name="confirm_password"
                placeholder="영문 대/소문자,숫자,특수문자 포함 8~16자리"
                feedback={(() => {
                  if (!feedback) return undefined;
                  if (feedback.confirm_password !== "") {
                    return {
                      status: false,
                      message: feedback.confirm_password,
                    };
                  }
                  return { status: true };
                })()}
              />
            </div>
            <div style={{ width: "50%", margin: "50px auto 0" }}>
              <CustomButton type="button" fullWidth onClick={changePassword}>
                설정 완료
              </CustomButton>
            </div>
          </div>
        </Grid>
      </form>
      <CustomDialog open={passwordConfirmed} maxWidth="md">
        <div className="text-align-center">
          <p className="font-header">비밀번호가 변경되었습니다</p>
          <div className="mt-30">
            <Link to="/signin" style={{ textDecoration: "none" }}>
              <CustomButton
                outlined
                onClick={() => {
                  setPasswordConfirmed(false);
                }}
              >
                로그인 페이지로 돌아가기
              </CustomButton>
            </Link>
          </div>
        </div>
      </CustomDialog>
    </Fragment>
  );
}

function PasswordComponent() {
  const [form, setForm] = useState<PasswordFindForm>({
    email: "",
    name: "",
    phone: "",
  });
  const [passwordRequest, setPasswordRequest] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const history = useHistory();

  const handleChange = (e: React.FormEvent<HTMLFormElement>) => {
    const target = e.target as HTMLInputElement;
    setForm({
      ...form,
      [target.name]: target.value,
    });
  };

  const requestFindPassword = () => {
    fetchDefaultWithCredential(`/user/password/find`, "POST", form).then(
      (res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => setErrorMessage(error));
        }
        return res.json().then(() => {
          setPasswordRequest(true);
        });
      }
    );
  };

  if (passwordRequest) {
    return (
      <>
        <div
          style={{
            fontSize: 25,
            fontWeight: 800,
            textAlign: "center",
          }}
        >
          인증하신 이메일로
          <br />
          비밀번호 재설정 메일을
          <br />
          발송했습니다.
        </div>
        <div
          style={{
            marginTop: 30,
          }}
        >
          <Button
            style={{
              backgroundColor: "#292929",
              padding: 15,
              borderRadius: 10,
              color: "white",
              fontWeight: 700,
            }}
            fullWidth
            onClick={() => {
              setPasswordRequest(false);
              history.push("/signin");
            }}
          >
            로그인 화면 돌아가기
          </Button>
        </div>
      </>
    );
  }

  return (
    <Fragment>
      <form onBlur={handleChange}>
        <div
          style={{
            fontSize: 25,
            fontWeight: 800,
            textAlign: "center",
          }}
        >
          비밀번호 찾기
        </div>
        <div className="text-align-center" style={{ marginTop: 15 }}>
          필로어스에 가입한 계정정보를 입력해주세요.
        </div>
        <div style={{ marginTop: 15 }}>
          <CustomText
            width="100%"
            type="text"
            label="이름"
            name="name"
            placeholder="이름을 입력해주세요."
          />
          <div style={{ marginTop: 15 }}>
            <CustomText
              width="100%"
              type="phone"
              label="전화번호"
              name="phone"
              placeholder="전화번호를 입력해주세요."
            />
          </div>
          <div style={{ marginTop: 15 }}>
            <CustomText
              width="100%"
              type="email"
              label="이메일 인증요청"
              name="email"
              placeholder="등록된 이메일을 입력해주세요."
            />
          </div>
          {Boolean(errorMessage) && (
            <div
              style={{
                marginTop: 15,
                color: "#FF0000",
                textAlign: "center",
                fontWeight: 700,
              }}
            >
              {errorMessage}
            </div>
          )}
          <div
            style={{
              marginTop: 30,
            }}
          >
            <Button
              style={{
                backgroundColor: "#6939CD",
                padding: 15,
                borderRadius: 10,
                color: "white",
                fontWeight: 700,
              }}
              fullWidth
              onClick={requestFindPassword}
            >
              인증 요청하기
            </Button>
          </div>
        </div>
      </form>
      <CustomDialog open={passwordRequest} maxWidth="md">
        <div className="text-align-center">
          <p className="font-header">
            인증하신 이메일로 <br />
            비밀번호 재수정 메일을 발송했습니다.
          </p>
          <div className="mt-30">
            <Link to="/signin" style={{ textDecoration: "none" }}>
              <CustomButton outlined onClick={() => setPasswordRequest(false)}>
                확인
              </CustomButton>
            </Link>
          </div>
        </div>
      </CustomDialog>
    </Fragment>
  );
}

function CompletedComponent() {
  return <Fragment></Fragment>;
}

function ForgotComponent() {
  return (
    <Fragment>
      <p className="font-header text-align-center">계정 정보를 잊으셨나요?</p>
      <div className="mt-30">
        <Grid container justifyContent="center" spacing={2}>
          <Grid item>
            <Link to="/signin?status=email" style={{ textDecoration: "none" }}>
              <CustomButton>이메일 찾기</CustomButton>
            </Link>
          </Grid>
          <Grid item>
            <Link
              to="/signin?status=password"
              style={{ textDecoration: "none" }}
            >
              <CustomButton>비밀번호 찾기</CustomButton>
            </Link>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
}

const statusList = [
  "default",
  "forgot",
  "completed",
  "email",
  "password",
  "password2",
];

const statusMappingContent = {
  default: <SigninForm />,
  forgot: <ForgotComponent />,
  completed: <CompletedComponent />,
  email: <EmailFindComponent />,
  password: <PasswordComponent />,
  password2: <Password2Component />,
};

interface SigninContextProps {
  redirect?: string;
}

const SigninContext = React.createContext({} as SigninContextProps);

interface SigninProps {
  stay?: boolean;
}

function Signin({ stay }: SigninProps) {
  const [status, setStatus] = useState<StatusProps>("default");
  const [loaded, setLoaded] = useState<boolean>(false);
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const user = useUser();
  const history = useHistory();
  useEffect(() => {
    /**
     * if user exists, check last signed in
     * if last signed in is not null, then move to home,
     * if it's null, then show first_signed_in
     * else, work on based on status on query string
     */
    if (parsed.status && statusList.includes(parsed.status as StatusProps)) {
      if (parsed.status === "password2") {
        if (!parsed.token) {
          setStatus("default");
          setLoaded(true);
          return;
        }
        fetchDefaultWithCredential(`/user/token/verify`, "POST", {
          token: parsed.token,
        }).then((res) => {
          if (!res.ok) {
            setStatus("default");
            setLoaded(true);
            return;
          }
          setStatus(parsed.status as StatusProps);
          setLoaded(true);
        });
      } else {
        setStatus(parsed.status as StatusProps);
        setLoaded(true);
      }
    } else {
      setStatus("default");
      setLoaded(true);
    }
  }, [parsed, user, history, location]);

  if (!loaded) return null;

  const state = location.state as any;
  let redirect = !stay ? "/" : undefined;
  if (state) {
    redirect = state.redirect;
  }

  return (
    <SigninContext.Provider value={{ redirect }}>
      <div style={{ display: isMobile() ? undefined : "flex" }}>
        <div
          style={{
            flex: 1,
            backgroundImage: `url(${SignPng})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: isMobile() ? 120 : 800,
          }}
        ></div>
        <div
          style={{
            flex: 1,
            color: status === "default" ? "white" : "#292929",
            background:
              status === "default"
                ? "linear-gradient(180deg, #6939CD 0%, #3B3740 100%)"
                : "white",
            alignSelf: "stretch",
          }}
        >
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: isMobile() ? undefined : 400,
              margin: "0px auto",
              padding: isMobile() ? "50px 20px" : "50px 0px",
            }}
          >
            {statusMappingContent[status]}
          </div>
        </div>
      </div>
    </SigninContext.Provider>
  );
}

export default Signin;
