import React, { useState } from "react";
import { formatDashedDate } from "../../../../utils";
import CustomSelect from "../../../customs/CustomSelect";
import CustomText from "../../../customs/CustomText";
import { responsivePercentage } from "../../../Home";
import { Authorized, SignupForm } from "../../../layouts/Signup";
import CustomDialog from "../../../customs/CustomDialog";
import CustomButton from "../../../customs/CustomButton";

function AdminPagesCustomSignup() {
  const [form, setForm] = useState<SignupForm>({
    name: "",
    gender: "male",
    phone: "000-0000-0000",
    date_of_birth: formatDashedDate(new Date()),
    email: "",
    password: "",
    confirm_password: "",
    private_info: false,
    marketing_info: true,
    job: "",
    how_philous: "",
    how_philous_detail: "",
    job_detail: "",
  });
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      <div
        className="margin-center"
        style={{
          width: responsivePercentage(30),
        }}
      >
        <div>
          <CustomText
            type="text"
            label="이름"
            name="name"
            style={{ width: "100%" }}
            handleBlur={(e) => setForm({ ...form, name: e.target.value })}
          />
        </div>
        <div className="mt-10">
          <CustomSelect
            name="gender"
            label="성별"
            items={[
              {
                label: "남자",
                value: "male",
              },

              {
                label: "여자",
                value: "female",
              },
            ]}
            value={form.gender}
            handleChange={(value) =>
              setForm({
                ...form,
                gender: value as string,
              })
            }
          />
        </div>
        <div className="mt-10">
          <CustomText
            type="phone"
            label="휴대전화 번호"
            name="phone"
            style={{ width: "100%" }}
            readOnly
            value={form.phone}
          />
        </div>
        <div className="mt-10">
          <p className="font-14 font-bold">생년월일</p>
          <input
            type="date"
            value={form.date_of_birth}
            onChange={(e) =>
              setForm({ ...form, date_of_birth: e.target.value })
            }
            className="width-100-percentage"
          />
        </div>
        <Authorized
          form={form}
          setForm={setForm}
          handleNext={() => setOpen(true)}
        />
      </div>
      <CustomDialog open={open}>
        <div className="text-align-center">
          <p className="font-header">회원 등록이 완료되었습니다</p>
          <CustomButton onClick={() => setOpen(false)}>확인</CustomButton>
        </div>
      </CustomDialog>
    </>
  );
}

export default AdminPagesCustomSignup;
