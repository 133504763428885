import {
  createTheme,
  FormControl,
  MenuItem,
  Select,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  InputLabel,
} from "@mui/material";
import React, { ReactNode, useRef } from "react";
import { StatusProps } from "../../interfaces";
import { isMobile } from "../../utils";
import { widthPercentage } from "../Home";
import "../Home.scss";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

interface ItemProp<T> {
  label: ReactNode;
  value: T;
  disabled?: boolean;
}

interface PropType<T> {
  name?: string;
  label?: string;
  items: ItemProp<T>[];
  handleChange: (value: T) => void;
  value: T;
  feedback?: StatusProps;
  labelStyle?: object;
  dark?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  fullWidth?: boolean;
}

function CustomSelect<T>({
  name,
  label,
  items,
  handleChange,
  value,
  feedback,
  labelStyle,
  dark,
  disabled,
  readOnly,
  fullWidth,
}: PropType<T>) {
  const ref = useRef<HTMLInputElement>(null);

  const theme = createTheme({
    palette: {
      mode: dark ? "dark" : "light",
    },
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <FormControl disabled={disabled} fullWidth={fullWidth}>
          <div
            style={{
              fontSize: 18,
              fontWeight: 700,
              marginBottom: 10,
              color: dark ? "white" : "#292929",
              ...labelStyle,
            }}
          >
            {label}
          </div>
          <Select
            ref={ref}
            value={value}
            name={name}
            onChange={(e) => handleChange(e.target.value as any)}
            displayEmpty
            readOnly={readOnly}
            style={{
              fontSize: 18,
              backgroundColor: (() => {
                if (readOnly) {
                  return "#ECECEC";
                }
                if (feedback && !feedback.status) {
                  return "#FF000426";
                }
                return "white";
              })(),
            }}
            sx={{
              "&.MuiOutlinedInput-root": {
                borderRadius: "13px",
              },
              "& .MuiSelect-select": {
                padding: "15px",
              },
              "& fieldset": {
                borderColor: "#AAAAAA",
              },
            }}
          >
            {items.map((item, index) => (
              <MenuItem
                value={item.value as any}
                key={index}
                disabled={item.disabled}
              >
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {feedback && !feedback.status && feedback.message && (
          <div className="text-red font-14">{feedback.message}</div>
        )}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default CustomSelect;

interface CustomStandardSelectProps<T> {
  value: T;
  items: ItemProp<T>[];
  handleChange: (value: T) => void;
  formStyle?: React.CSSProperties;
  label?: string;
  fullWidth?: boolean;
}

export function CustomStandardSelect<T>({
  value,
  items,
  handleChange,
  formStyle,
  label,
  fullWidth,
}: CustomStandardSelectProps<T>) {
  return (
    <FormControl variant="standard" style={formStyle} fullWidth={fullWidth}>
      {label && (
        <InputLabel
          shrink
          className="font-noto-medium"
          style={{
            color: "#767676",
            fontSize: widthPercentage(isMobile() ? 3.5 : 0.875),
          }}
        >
          {label}
        </InputLabel>
      )}
      <Select<T>
        value={value}
        onChange={(e) => handleChange(e.target.value as T)}
        displayEmpty
        style={{
          fontSize: widthPercentage(isMobile() ? 4 : 1),
          marginTop: widthPercentage(isMobile() ? 3 : 0.75),
        }}
        sx={{
          ".MuiInputBase-input": {
            padding: `${widthPercentage(
              isMobile() ? 1 : 0.25
            )}px ${widthPercentage(isMobile() ? 6 : 1.5)}px`,
            paddingLeft: 0,
          },
        }}
      >
        {items.map((item, index) => (
          <MenuItem
            value={item.value as any}
            key={index}
            disabled={item.disabled}
          >
            <div
              style={{
                color: "#111111",
                fontSize: widthPercentage(isMobile() ? 4 : 1),
              }}
            >
              {item.label}
            </div>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
