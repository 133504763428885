import { ENVIRONMENT, SERVER } from "./config";
import { User, UserRole } from "./globals/user";
import { Image, TemplateMap } from "./interfaces";
export const second = 1000;
export const minute = 60 * second;
export const hour = 60 * minute;
export const day = 24 * hour;
export const month = 30 * day;
export const year = 365 * day;

export function fetchWithCredential(
  URL: string,
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH",
  body?: object
): Promise<Response> {
  const headers: Headers | string[][] | Record<string, string> | undefined = {};
  headers.environment = ENVIRONMENT;
  if (window.localStorage.getItem("credential")) {
    headers.Authorization = "Bearer " + window.localStorage.getItem("credential");
  }

  if (body) {
    headers["Content-Type"] = "application/json";
  }

  return fetch(URL, {
    method,
    mode: "cors",
    credentials: "include",
    headers,
    ...(body && { body: JSON.stringify(body) }),
  }).then((res) => {
    if (!res.ok) {
      if (res.status === 401) {
        return res.json().then(({ detail, token, error }) => {
          if (detail === "refresh") {
            window.localStorage.setItem("credential", token);
            return fetchWithCredential(URL, method, body);
          }
          window.localStorage.removeItem("credential");
          return new Promise(() => (window.location.href = "/"));
        });
      }
    }
    return res;
  });
}

export function fetchDefaultWithCredential(
  URL: string,
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH",
  body?: object
): Promise<Response> {
  return fetchWithCredential(`${SERVER}${URL}`, method, body);
}

export function fetchFileWithCredential(
  URL: string,
  method: "GET" | "POST" | "PUT" | "DELETE",
  body?: FormData
): Promise<Response> {
  const headers: Headers | string[][] | Record<string, string> | undefined = {};

  if (window.localStorage.getItem("credential")) {
    headers.Authorization = "Bearer " + window.localStorage.getItem("credential");
  }

  return fetch(URL, {
    method,
    mode: "cors",
    credentials: "include",
    headers,
    body,
  }).then((res) => {
    if (!res.ok) {
      if (res.status === 401) {
        return res.json().then(({ detail, token, error }) => {
          if (detail === "refresh") {
            window.localStorage.setItem("credential", token);
            return fetchFileWithCredential(URL, method, body);
          }
          window.localStorage.removeItem("credential");
          return new Promise(() => (window.location.href = "/"));
        });
      }
    }
    return res;
  });
}

export function fetchDefaultFileWithCredential(
  URL: string,
  method: "GET" | "POST" | "PUT" | "DELETE",
  body?: FormData
): Promise<Response> {
  return fetchFileWithCredential(`${SERVER}${URL}`, method, body);
}

export function putDefaultFileWithCredential(
  URL: string,
  file: File
): Promise<Response> {
  const formData = new FormData();
  formData.append("file", file);
  return fetchFileWithCredential(`${SERVER}${URL}`, "PUT", formData);
}

export function putImageWithToken(
  token: string,
  file: File | Blob
): Promise<Image> {
  const formData = new FormData();
  formData.append("image", file);
  return fetchFileWithCredential(
    `${SERVER}/image/token/${token}`,
    "PUT",
    formData
  )
    .then((res) => {
      if (!res.ok) {
        return res.text().then((data) => {
          throw new Error(data);
        });
      }
      return res.json();
    })
    .catch((err) => console.log(err));
}

export function formatDate(dateString: string): string {
  const date = new Date(dateString);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  return [month, day, year].join("/");
}

export const wordMap: {
  [key: string]: string;
} = {
  id: "번호",
  created_at: "생성 일",
  updated_at: "업데이트 일",
  hierarchy: "권한",
  username: "ID",
  ADMIN: "관리자",
  OPERATOR: "운영자",
  CLIENT: "회원",
  name: "이름",
  gender: "성별",
  age: "나이",
  address: "주소",
  job: "직업",
  phone: "전화번호",
  M: "남자",
  F: "여자",
  email: "이메일",
  author: "작가",
  Add: "추가",
  Update: "업데이트",
  oneday: "원데이",
  monthly: "먼슬리",
  meeting: "미팅방식",
  date: "날짜",
  price: "가격",
  online: "온라인",
  offline: "오프라인",
  main: "메인",
  sub: "서브",
  paid: "지불",
  refund: "환불",
  action: "현황",
  amount: "금액",
  point: "포인트",
  mypage: "마이페이지",
  cart: "장바구니",
  programParticipationCount: "참여 프로그램 수",
  yes: "예",
  no: "아니오",
  tutor: "튜터여부",
};

export function assignDateToTemplateObjet(template: TemplateMap, data: any) {
  Object.assign(template, extractIdDates(data));
  for (let i = 0; i < template.forms.length; i++) {
    Object.assign(template.forms[i], extractIdDates(data.forms[i]));
    for (let j = 0; j < template.forms[i].choices.length; j++) {
      Object.assign(
        template.forms[i].choices[j],
        extractIdDates(data.forms[i].choices[j])
      );
    }
  }
}

export function extractIdDates({
  id,
  created_at,
  updated_at,
}: {
  id: number;
  created_at: string;
  updated_at: string;
}) {
  return {
    id,
    created_at,
    updated_at,
  };
}

export function numberOnly(str: string): boolean {
  return /^\d*$/.test(str);
}

export const hierarchyMap = {
  ADMIN: 0,
  OPERATOR: 1,
  CLIENT: 2,
};

export function getCurrentMonth() {
  return new Date().getMonth() + 1;
}

export function yearMonth(date: Date) {
  return `${date.getFullYear()}-${date.getMonth() + 1}`;
}

export function formatDateJoined(date: Date, param: string) {
  const month = (date.getMonth() + 1).toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  });
  const dateNumber = date.getDate().toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  });
  const year = date.getFullYear();
  return [year, month, dateNumber].join(param);
}

export function formatDashedDate(date: Date) {
  return formatDateJoined(date, "-");
}

export function formatDashedDateTemplate(date: string) {
  const [year, month, day] = date.split("-");
  return `${year}년 ${month}월 ${day}일`;
}

export function formatDateTemplateDateType(date: Date) {
  return `${date.getFullYear()}년 ${date.getMonth() + 1}월 ${date.getDate()}일`;
}

export function formatDateTemplate(dateString: string) {
  return formatDateTemplateDateType(new Date(dateString));
}

export function formatDateTemplateWithNoYear(dateString: string) {
  const date = new Date(dateString);
  return `${date.getMonth() + 1}월 ${date.getDate()}일`;
}

export function formatTimeTemplate(dateString: string) {
  const date = new Date(dateString);
  return date.toLocaleTimeString("ko-KR", { timeStyle: "short" });
}

export function getCookie(cname: string) {
  const name = cname + "=";
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function myPopup(
  url: string,
  title: string,
  myWidth: number,
  myHeight: number
) {
  const left = (window.screen.width - myWidth) / 2;
  const top = (window.screen.height - myHeight) / 4;
  return window.open(
    url,
    title,
    "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
      myWidth +
      ", height=" +
      myHeight +
      ", top=" +
      top +
      ", left=" +
      left
  );
}

export function setCookie(cname: string, cvalue: string, exdays: number) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getMonthDateSoFar(date: string) {
  const dateObject = new Date(date);
  const nowDate = new Date();
  const timeDiff = nowDate.getTime() - dateObject.getTime();
  const monthTime = 1000 * 60 * 60 * 24 * 30;
  return Math.floor(timeDiff / monthTime) + 1;
}

export function commaSeparateArray(array: any[]) {
  return array.join(",");
}

export function formClass(classes: (string | null)[]) {
  return classes.filter((classObject) => classObject !== null).join(" ");
}

export function getAge(date: string) {
  const dateObject = new Date(date);
  const yearBorn = dateObject.getFullYear();
  const currentDateObject = new Date();
  const currentYear = currentDateObject.getFullYear();
  return currentYear - yearBorn + 1;
}

const roleScore: any = {
  admin: 0,
  operator1: 1,
  operator2: 2,
  user: 3,
};

export const roleLabelMap: any = {
  admin: "최고 관리자",
  operator1: "메인 관리자",
  operator2: "서브 관리자",
  user: "회원",
};

export const roles = Object.keys(roleScore);

export function eligibleHierarchy(user1: User, user2: User) {
  // this calculates if user1 has access to user2
  if (roleScore[user1.role] < roleScore[user2.role]) {
    return true;
  }
  return false;
}

export function isEligible(user: User, role: UserRole) {
  // this calculates if user1 has access to user2
  if (roleScore[user.role] <= roleScore[role]) {
    return true;
  }
  return false;
}

export function eligibleRoles(user: User) {
  return roles.filter((role) => roleScore[role] > roleScore[user.role]);
}

const MOBILE_WIDTH = 600;

export function isMobile() {
  return isSizeLessThan(MOBILE_WIDTH);
}

export function isSizeLessThan(size: number) {
  return window.innerWidth < size;
}

export function getWeekDay(date: Date) {
  const weekday = ["일", "월", "화", "수", "목", "금", "토"];
  return weekday[date.getDay()];
}

export function formatProgramDate(dates: string[]) {
  if (dates.length === 0) return "";
  let minDate = new Date(dates[0]);
  for (let i = 1; i < dates.length; i++) {
    const date = new Date(dates[i]);
    if (minDate.getTime() > date.getTime()) {
      minDate = date;
    }
  }
  return formatDateString(
    minDate.toLocaleDateString("en-US", {
      timeZone: "Asia/Seoul",
    })
  );
}

export function formatDateString(dateString: string) {
  const dateObject = new Date(dateString);
  const convertedDateString = dateObject.toLocaleDateString("en-US", {
    timeZone: "Asia/Seoul",
  });
  const [month, date, year] = convertedDateString
    .split(", ")[0]
    .split("/")
    .map((number) => parseInt(number));
  const minDateObject = new Date(year, month - 1, date);
  return `${year}년 ${month}월 ${date}일 (${getWeekDay(minDateObject)})`;
}

export function getTimePrefix(timeHour: number) {
  let prefix = "오전";
  if (timeHour >= 12) {
    prefix = "오후";
  }
  return prefix;
}

export function getKRTimeFormat(hour: number, minute: number) {
  const prefix = getTimePrefix(hour);
  return `${prefix} ${((12 + hour - 1) % 12) + 1}:${minute.toLocaleString(
    undefined,
    {
      minimumIntegerDigits: 2,
    }
  )}`;
}

export function formatProgramTime(
  timeHour: number,
  timeMinute: number,
  durations: number
) {
  const date = new Date(2011, 0, 1, timeHour, timeMinute);
  const time = date.getTime() + durations * minute;
  const toDate = new Date(time);
  return `${getKRTimeFormat(timeHour, timeMinute)} - ${getKRTimeFormat(
    toDate.getHours(),
    toDate.getMinutes()
  )}`;
}

export function formatMonthDate(date: string) {
  const dateObject = new Date(date);
  return `${
    dateObject.getMonth() + 1
  }월 ${dateObject.getDate()}일 (${getWeekDay(dateObject)})`;
}

export function formatDashedMonthDate(dashedDate: string) {
  const splitted = dashedDate.split("-");
  const dateObject = new Date(dashedDate);
  return `${splitted[1]}월 ${splitted[2]}일 (${getWeekDay(dateObject)})`;
}

export function formatProgramDetailDates(dates: string[]) {
  dates = dates.sort();
  const monthDateMap: any = {};
  const monthDayMap: any = {};
  for (const date of dates) {
    const dateObject = new Date(date);
    const dateString = dateObject.toLocaleDateString("en-US", {
      timeZone: "Asia/Seoul",
    });
    const parsed = dateString
      .split(", ")[0]
      .split("/")
      .map((number) => parseInt(number));
    const month = parsed[0];
    const refinedDateObject = new Date(parsed[2], parsed[0] - 1, parsed[1]);
    if (!(month in monthDateMap)) {
      monthDateMap[month] = [];
      monthDayMap[month] = getWeekDay(refinedDateObject);
    }
    monthDateMap[month].push(refinedDateObject.getDate());
  }
  const months = Object.keys(monthDateMap)
    .map(Number)
    .sort((a, b) => a - b);
  return months
    .map(
      (month) =>
        `${month}월 ${monthDateMap[month].join(",")}일 (${monthDayMap[month]})`
    )
    .join(" / ");
}

export function formatMonthsToYear(months: number) {
  const years = Math.floor(months / 12);
  const newMonths = months % 12;
  if (years === 0) {
    return `${newMonths}개월`;
  }
  if (newMonths === 0) {
    return `${years}년`;
  }
  return `${years}년 ${newMonths}개월`;
}

export const graphColors = [
  "#fd7f6f",
  "#7eb0d5",
  "#b2e061",
  "#bd7ebe",
  "#ffb55a",
  "#ffee65",
  "#beb9db",
  "#fdcce5",
  "#8bd3c7",
];

export function isDigitsOnly(str: string) {
  return /^\d+$/.test(str);
}

export function calculateElapsedDays(date: string) {
  if (date === "") return 0;
  const date1 = new Date(date);
  const date2 = new Date();
  const elapsedDays = Math.floor(
    (date2.getTime() - date1.getTime()) / (1000 * 60 * 60 * 24)
  );
  return elapsedDays;
}

export function getKoreanNowDate() {
  const now = new Date();
  const koreanDate = now.toLocaleDateString("en-US", {
    timeZone: "Asia/Seoul",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  const [month, day, year] = koreanDate.split("/");
  return [year, month, day].join("-");
}

export function getMinimumString(strings: string[]) {
  const minString = strings.reduce(
    (min, current) => (current < min ? current : min),
    strings[0]
  );
  return minString;
}
