import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import CustomDialog from "../../components/customs/CustomDialog";
import CustomButton from "../../components/customs/CustomButton";
import { fetchDefaultWithCredential, isMobile } from "../../utils";
import { HomeDialog } from "../../components/Home";
import queryString from "query-string";
import ErrorIcon from "@mui/icons-material/Error";
import { ExternalTutorInterface } from "../../interfaces";
import { ReactComponent as TutorInterviewSvg } from "../../svg/tutor_interview.svg";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { SwiperPagination } from "../../components/customs/HomeSwiper";

export default function TutorDialogComponent() {
  const history = useHistory();
  const location = useLocation();
  const [tutorInfo, setTutorInfo] = useState<ExternalTutorInterface | null>(
    null
  );
  const [unprepared, setUnprepared] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);
  const [reviewIndex, setReviewIndex] = useState<number>(0);
  const parsed = queryString.parse(location.search);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    // interview popup
    if (!("tutor_detail_id" in parsed)) {
      setTutorInfo(null);
    } else {
      // check location search
      const tutorInterviewId =
        parseInt(parsed["tutor_detail_id"] as string) || 0;
      fetchDefaultWithCredential(`/tutor/${tutorInterviewId}`, "GET").then(
        (res) => {
          if (!res.ok) {
            if (res.status === 400) {
              // update router
              delete parsed.tutor_detail_id;
              location.search = queryString.stringify(parsed);
              history.replace({ search: location.search });
              setUnprepared(false);
              return;
            }
            return res.json().then(({ error }) => {
              throw new Error(error);
            });
          }
          return res.json().then((tutorInfo) => {
            if (!tutorInfo) {
              setUnprepared(true);
              return;
            }
            setTutorInfo(tutorInfo);
          });
        }
      );
    }
  }, [history, location]);

  const handleClose = () => {
    const parsed = queryString.parse(location.search);
    delete parsed.tutor_detail_id;
    location.search = queryString.stringify(parsed);
    history.replace({ search: location.search });
    setUnprepared(false);
  };

  return (
    <Fragment>
      <HomeDialog open={Boolean(tutorInfo)} handleClose={handleClose}>
        {tutorInfo &&
          (() => {
            const { tutor_name, introduction, detail, tutor_interview } =
              tutorInfo;
            if (isMobile())
              return (
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      height: 400,
                      backgroundImage: `url(${
                        tutor_interview?.image1 || tutorInfo.tutor_avatar
                      })`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        inset: 0,
                        background: `linear-gradient(180deg, rgba(255, 255, 255, 0) 24%, rgba(255, 255, 255, 0.95) 79.5%)`,
                      }}
                    ></div>
                    <div
                      style={{
                        position: "absolute",
                        left: 30,
                        bottom: 30,
                        right: 30,
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#D4D0FE",
                          fontWeight: 600,
                          fontSize: 14,
                          userSelect: "none",
                          color: "#6D66B5",
                          borderRadius: 25,
                          display: "inline",
                          padding: "5px 10px",
                        }}
                      >
                        튜터의 한마디
                      </div>
                      <div style={{ marginTop: 10, fontSize: 14 }}>
                        "{detail}"
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      borderRadius: "40px 40px 0px 0px",
                      backgroundColor: "white",
                      boxShadow: "0px -2px 18.7px rgba(0, 0, 0, 0.12)",
                      padding: 30,
                      position: "relative",
                    }}
                  >
                    <div
                      className="whitespace-preline pretendard"
                      style={{
                        textAlign: "center",
                        fontWeight: 200,
                        fontSize: 30,
                      }}
                    >
                      {introduction}
                    </div>
                    <div
                      style={{
                        marginTop: 15,
                        fontSize: 16,
                        fontWeight: 700,
                        textAlign: "center",
                      }}
                    >
                      {tutor_name} 튜터
                    </div>
                    {tutor_interview && (
                      <div
                        style={{
                          marginTop: 130,
                          backgroundColor: "#EFEEF8",
                          marginLeft: -30,
                          marginRight: -30,
                        }}
                      >
                        <div style={{ paddingTop: 100 }}></div>
                        <div
                          style={{
                            margin: 30,
                            marginTop: -200,
                            height: 225,
                            backgroundImage: `url(${tutor_interview.image2})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        ></div>
                        <div style={{ overflow: "auto", marginBottom: 30 }}>
                          <div
                            style={{
                              paddingLeft: 30,
                              paddingRight: 30,
                              display: "inline-flex",
                              columnGap: 10,
                            }}
                          >
                            {(() => {
                              const res = [];
                              for (
                                let i = 0;
                                i < tutor_interview.interviews.length;
                                i++
                              ) {
                                res.push(
                                  <div key={i}>
                                    <div
                                      style={{
                                        width: 50,
                                        height: 50,
                                        borderRadius: "50%",
                                        backgroundColor:
                                          i === index ? "#D4D0FE" : "white",
                                        color:
                                          i === index ? "black" : "#999999",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontSize: 20,
                                        fontWeight: 600,
                                        cursor: "pointer",
                                      }}
                                      onClick={() => setIndex(i)}
                                      className="preahvihear-regular"
                                    >
                                      Q{i + 1}
                                    </div>
                                  </div>
                                );
                              }
                              return res;
                            })()}
                          </div>
                        </div>
                        <div
                          style={{
                            height: 400,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: "white",
                              fontSize: 18,
                              fontWeight: 800,
                              padding: "15px 30px",
                              textAlign: "center",
                            }}
                          >
                            {tutor_interview.interviews[index].question}
                          </div>
                          <div
                            style={{
                              fontSize: 16,
                              fontWeight: 500,
                              padding: 30,
                              height: "100%",
                              overflow: "auto",
                            }}
                          >
                            {tutor_interview.interviews[index].answer}
                          </div>
                        </div>
                        <div
                          style={{
                            marginTop: 30,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              padding: 10,
                              backgroundColor: "#EBEBEB",
                              color: "#C8C8C8",
                              cursor: "pointer",
                              visibility: index > 0 ? "visible" : "hidden",
                            }}
                          >
                            <WestIcon onClick={() => setIndex(index - 1)} />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              padding: 10,
                              backgroundColor: "#EBEBEB",
                              color: "#C8C8C8",
                              cursor: "pointer",
                              visibility:
                                index < tutor_interview.interviews.length - 1
                                  ? "visible"
                                  : "hidden",
                            }}
                          >
                            <EastIcon onClick={() => setIndex(index + 1)} />
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      style={{
                        padding: 30,
                        paddingTop: 30,
                        textAlign: "center",
                      }}
                    >
                      <div
                        style={{
                          fontSize: 30,
                          fontWeight: 100,
                        }}
                      >
                        <span
                          style={{
                            color: "#6939CD",
                            fontWeight: 900,
                          }}
                        >
                          {tutor_name} 튜터
                        </span>
                        와
                        <br />
                        함께 하신 분들의
                        <br />
                        세미나 후기
                      </div>
                    </div>
                    <div
                      style={{
                        marginLeft: "auto",
                        display: "flex",
                        flexDirection: "column",
                        rowGap: 30,
                      }}
                    >
                      {Object.keys(tutorInfo.tutor_selected_reviews)
                        .filter(
                          (reviewId) =>
                            tutorInfo.tutor_selected_reviews[reviewId].active
                        )
                        .slice(3 * reviewIndex, 3 * (reviewIndex + 1))
                        .map((reviewId, index) => (
                          <div
                            key={index}
                            style={{
                              padding: 30,
                              borderRadius: 20,
                              boxShadow: "0px 2px 16.6px 0px #00000030",
                              display: "flex",
                              alignItems: "center",
                              paddingRight: 10,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              parsed.review_id = reviewId;
                              parsed.index = (-1).toString();
                              location.search = queryString.stringify(parsed);
                              history.replace({ search: location.search });
                            }}
                          >
                            <div style={{ flex: 1, wordBreak: "break-all" }}>
                              <div
                                style={{
                                  fontSize: 16,
                                  fontWeight: 700,
                                }}
                              >
                                {
                                  tutorInfo.tutor_selected_reviews[reviewId]
                                    .title
                                }
                              </div>
                              <div
                                style={{
                                  marginTop: 10,
                                  display: "-webkit-box",
                                  WebkitLineClamp: 2,
                                  WebkitBoxOrient: "vertical",
                                  overflow: "hidden",
                                  fontSize: 14,
                                  fontWeight: 400,
                                }}
                              >
                                {
                                  tutorInfo.tutor_selected_reviews[reviewId]
                                    .detail
                                }
                              </div>
                            </div>
                            <ChevronRightIcon />
                          </div>
                        ))}
                      {Object.keys(tutorInfo.tutor_selected_reviews).filter(
                        (reviewId) =>
                          tutorInfo.tutor_selected_reviews[reviewId].active
                      ).length > 0 && (
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <SwiperPagination
                            amount={Math.ceil(
                              Object.keys(tutorInfo.tutor_selected_reviews)
                                .length / 3
                            )}
                            ithButtonClicked={setReviewIndex}
                            currentIndex={reviewIndex + 1}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            return (
              <div className="pretendard">
                <div style={{ padding: 50, width: 1000, position: "relative" }}>
                  <div
                    style={{
                      width: 400,
                      height: 500,
                      backgroundImage: `url(${
                        tutor_interview?.image1 || tutorInfo.tutor_avatar
                      })`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      position: "absolute",
                      right: 0,
                      top: 0,
                    }}
                  ></div>
                  <div
                    className="whitespace-preline"
                    style={{
                      width: 500,
                      fontSize: 40,
                      fontWeight: 200,
                    }}
                  >
                    {introduction}
                  </div>
                  <div style={{ marginTop: 5, fontSize: 20, fontWeight: 700 }}>
                    {tutor_name} 튜터
                  </div>
                  <div style={{ marginTop: 100 }}>
                    <div
                      style={{
                        backgroundColor: "#D4D0FE",
                        fontWeight: 700,
                        fontSize: 14,
                        userSelect: "none",
                        color: "#6D66B5",
                        borderRadius: 25,
                        display: "inline",
                        padding: "5px 10px",
                      }}
                    >
                      튜터의 한마디
                    </div>
                    <div style={{ marginTop: 15, width: 450, fontWeight: 600 }}>
                      "{detail}"
                    </div>
                  </div>
                </div>
                {tutor_interview && (
                  <div
                    style={{
                      marginTop: 50,
                      position: "relative",
                      padding: 50,
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        bottom: 0,
                        width: 340,
                        backgroundColor: "#EFEEF8",
                      }}
                    ></div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <TutorInterviewSvg
                        style={{
                          width: 600,
                          height: "auto",
                          position: "relative",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        marginTop: 50,
                        display: "flex",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          width: 400,
                          height: 300,
                          backgroundImage: `url(${tutor_interview.image2})`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          borderRadius: 30,
                        }}
                      ></div>
                      {tutor_interview.interviews[index] && (
                        <div
                          style={{
                            marginLeft: 50,
                            width: 400,
                            marginTop: -30,
                            overflow: "auto",
                            height: 500,
                          }}
                        >
                          <div
                            className="preahvihear-regular"
                            style={{ fontSize: 70, fontWeight: 400 }}
                          >
                            Q{index + 1}.
                          </div>
                          <div
                            style={{
                              marginTop: 5,
                              fontSize: 18,
                              fontWeight: 800,
                            }}
                          >
                            {tutor_interview.interviews[index].question}
                          </div>
                          <div
                            style={{
                              marginTop: 15,
                              whiteSpace: "pre-line",
                              fontSize: 16,
                              fontWeight: 500,
                            }}
                          >
                            {tutor_interview.interviews[index].answer}
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        right: 0,
                        bottom: 0,
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          padding: 10,
                          backgroundColor: "#EBEBEB",
                          color: "#C8C8C8",
                          cursor: "pointer",
                          visibility: index > 0 ? "visible" : "hidden",
                        }}
                      >
                        <WestIcon onClick={() => setIndex(index - 1)} />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          padding: 10,
                          backgroundColor: "#EBEBEB",
                          color: "#C8C8C8",
                          cursor: "pointer",
                          visibility:
                            index < tutor_interview.interviews.length - 1
                              ? "visible"
                              : "hidden",
                        }}
                      >
                        <EastIcon onClick={() => setIndex(index + 1)} />
                      </div>
                    </div>
                  </div>
                )}
                <div style={{ padding: 50, display: "flex" }}>
                  <div
                    style={{
                      fontSize: 40,
                      fontWeight: 200,
                    }}
                  >
                    <span
                      style={{
                        color: "#6939CD",
                        fontWeight: 900,
                      }}
                    >
                      {tutor_name} 튜터
                    </span>
                    와
                    <br />
                    함께 하신 분들의
                    <br />
                    세미나 후기
                  </div>
                  <div
                    style={{
                      marginLeft: "auto",
                      display: "flex",
                      flexDirection: "column",
                      rowGap: 30,
                    }}
                  >
                    {Object.keys(tutorInfo.tutor_selected_reviews)
                      .filter(
                        (reviewId) =>
                          tutorInfo.tutor_selected_reviews[reviewId].active
                      )
                      .slice(3 * reviewIndex, 3 * (reviewIndex + 1))
                      .map((reviewId, index) => (
                        <div
                          key={index}
                          style={{
                            padding: 30,
                            width: 500,
                            borderRadius: 20,
                            boxShadow: "0px 2px 16.6px 0px #00000030",
                            display: "flex",
                            alignItems: "center",
                            paddingRight: 10,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            parsed.review_id = reviewId;
                            parsed.index = (-1).toString();
                            location.search = queryString.stringify(parsed);
                            history.replace({ search: location.search });
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            <div
                              style={{
                                fontSize: 18,
                                fontWeight: 700,
                              }}
                            >
                              {tutorInfo.tutor_selected_reviews[reviewId].title}
                            </div>
                            <div
                              style={{
                                marginTop: 10,
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                fontSize: 16,
                                fontWeight: 500,
                              }}
                            >
                              {
                                tutorInfo.tutor_selected_reviews[reviewId]
                                  .detail
                              }
                            </div>
                          </div>
                          <ChevronRightIcon />
                        </div>
                      ))}
                    {Object.keys(tutorInfo.tutor_selected_reviews).filter(
                      (reviewId) =>
                        tutorInfo.tutor_selected_reviews[reviewId].active
                    ).length > 0 && (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <SwiperPagination
                          amount={Math.ceil(
                            Object.keys(tutorInfo.tutor_selected_reviews)
                              .length / 3
                          )}
                          ithButtonClicked={setReviewIndex}
                          currentIndex={reviewIndex + 1}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })()}
      </HomeDialog>
      <CustomDialog open={unprepared}>
        <div className="p-30 text-align-center">
          <div className="d-flex justify-content-center">
            <div className="height-100">
              <ErrorIcon
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </div>
          </div>
          <p className="font-header">아직 인터뷰가 완료되지 않았습니다.</p>
          <p className="font-sub text-secondary">
            튜터 인터뷰는 매달 한분씩 업데이트 되고 있습니다. 곧 관심있는
            튜터분의 인터뷰도 업데이트될 예정이니 많이 기대해주세요!
          </p>
          <div className="text-align-center">
            <CustomButton outlined onClick={handleClose}>
              닫기
            </CustomButton>
          </div>
        </div>
      </CustomDialog>
    </Fragment>
  );
}
