import React, { Fragment, useCallback, useEffect, useState } from "react";
import { fetchDefaultWithCredential, isMobile } from "../utils";
import { GenericBannerComponent } from "./HeaderBanner";
import { Button } from "@mui/material";
import "./Program.scss";
import { useHistory } from "react-router-dom";
import { useProgramWait } from "./ProgramDetail";
import { textColor } from "./Home";
export type ProgramStatus = "all" | "progress" | "almost" | "done";

export interface ProgramProps {
  id: number;
  program_image: string;
  program_status: ProgramStatus;
  program_header: string;
  program_name: string;
  program_description: string;
  location: string;
  cart: boolean;
  minutes: number;
  dates: string[];
  starting_hour: number;
  starting_minute: number;
  bought: boolean;
  program_label: string;
  waiting_available: boolean;
  waiting: boolean;
  custom_date: boolean;
  custom_date_start: string;
  custom_date_end: string;
}

interface GroupedProgram {
  group_name: string;
  group_description: string;
  programs: ProgramProps[];
}

export function useGroupedPrograms() {
  const [groupedPrograms, setGroupedPrograms] = useState<GroupedProgram[]>([]);

  const refreshGroupedPrograms = useCallback(() => {
    fetchDefaultWithCredential("/program/list", "GET").then((res) => {
      if (!res.ok) {
        return res.json().then(({ error }) => {
          throw new Error(error);
        });
      }
      return res.json().then(setGroupedPrograms);
    });
  }, []);

  useEffect(() => {
    refreshGroupedPrograms();
  }, [refreshGroupedPrograms]);

  return { groupedPrograms, refreshGroupedPrograms };
}

export function ProgramStatusLabel(status: ProgramStatus) {
  let label = "";
  if (status === "all") {
    label = "ALL";
  } else if (status === "progress") {
    label = "모집중";
  } else if (status === "almost") {
    label = "마감임박";
  } else if (status === "done") {
    label = "마감";
  }
  return label;
}

interface ProgramStatusComponentProps {
  status: ProgramStatus;
}

export function ProgramStatusComponent({
  status,
}: ProgramStatusComponentProps) {
  let style: React.CSSProperties = {
    borderRadius: 3,
    textAlign: "center",
    display: "inline-block",
    fontSize: isMobile() ? 9 : 10,
    fontWeight: 700,
    padding: 2,
    width: isMobile() ? 55 : 60,
    lineHeight: 1.4,
  };
  if (status === "progress") {
    style = {
      ...style,
      color: "#fff",
      backgroundColor: "#2699fb",
    };
  } else if (status === "almost") {
    style = {
      ...style,
      color: "#fff",
      backgroundColor: "#ff5a6e",
    };
  } else if (status === "done") {
    style = {
      ...style,
      color: "#fff",
      backgroundColor: "#000",
    };
  }
  return <div style={style}>{ProgramStatusLabel(status)}</div>;
}

interface LocationComponentProps {
  location: string;
}

export function LocationComponent({ location }: LocationComponentProps) {
  return (
    <div
      style={{
        borderRadius: 3,
        textAlign: "center",
        display: "inline-block",
        fontSize: isMobile() ? 9 : 10,
        fontWeight: 700,
        padding: 2,
        width: isMobile() ? 55 : 60,
        lineHeight: 1.4,
        backgroundColor: "#EBEBEB",
      }}
    >
      {location}
    </div>
  );
}

interface ProgramLabelComponentProps {
  program_label: string;
}

export function ProgramLabelRightTopComponent({
  program_label,
}: ProgramLabelComponentProps) {
  let style: React.CSSProperties = {
    textAlign: "center",
    display: "inline-flex",
    fontSize: isMobile() ? 10 : 12,
    fontWeight: 700,
    padding: isMobile() ? 2.5 : 5,
    lineHeight: 1.4,
    borderRadius: "50%",
    width: isMobile() ? 35 : 45,
    height: isMobile() ? 35 : 45,
    border: "1px solid #FFFFFF4D",
    justifyContent: "center",
    alignItems: "center",
    wordBreak: "keep-all",
  };
  if (program_label === "NEW") {
    style = {
      ...style,
      color: "#6939CD",
      backgroundColor: "white",
    };
    return <div style={style}>{program_label}</div>;
  }
  if (program_label === "BEST") {
    style = {
      ...style,
      color: "#6939CD",
      backgroundColor: "#D4D0FE",
    };
    return <div style={style}>{program_label}</div>;
  }
  let word = program_label;
  // make split to front only
  word = word.split(" ")[0];
  // if length is 4, then split by 2 and 2
  if (word.length === 4) {
    word = word.substring(0, 2) + " " + word.substring(2);
  }
  if (["얼리버드 할인", "패키지 할인", "반짝 할인"].includes(program_label)) {
    style = {
      ...style,
      color: "#6939CD",
      backgroundColor: "#FFD66B",
    };
    return <div style={style}>{word}</div>;
  }
  if (program_label === "도서지원") {
    style = {
      ...style,
      color: "white",
      backgroundColor: "#009FC1",
    };
    return <div style={style}>{word}</div>;
  }
  return null;
}

interface ProgramComponentProps {
  program: ProgramProps;
  refreshGroupedPrograms: () => void;
}

export function ProgramComponent({ program }: ProgramComponentProps) {
  const history = useHistory();
  const { WaitDialog } = useProgramWait();

  return (
    <div
      style={{
        borderRadius: isMobile() ? 5 : 8,
        border: "1px solid #D9D9D9",
        overflow: "hidden",
        cursor: "pointer",
        position: "relative",
      }}
      onClick={() => history.push(`/program/${program.id}`)}
    >
      <div
        style={{
          height: isMobile() ? 250 : 380,
          backgroundImage: `url(${program.program_image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          borderBottom: "0.98px solid #D9D9D9",
        }}
      ></div>
      <div style={{ padding: isMobile() ? 10 : 15 }}>
        <div style={{ display: "flex", columnGap: 5 }}>
          <ProgramStatusComponent status={program.program_status} />
          <LocationComponent location={program.location} />
        </div>
        <div
          style={{
            marginTop: 5,
            fontSize: 13,
            fontWeight: 700,
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
          }}
        >
          {program.program_name}
        </div>
        <div
          style={{
            fontSize: isMobile() ? 11 : 13,
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
          }}
        >
          {program.program_description}
        </div>
        <div
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            display: "flex",
          }}
        >
          <ProgramLabelRightTopComponent
            program_label={program.program_label}
          />
        </div>
      </div>
      <WaitDialog />
    </div>
  );
}

interface ProgramVisualizeComponentProps {
  groupedProgram: GroupedProgram;
  refreshGroupedPrograms: () => void;
}

function ProgramVisualizeComponent({
  groupedProgram,
  refreshGroupedPrograms,
}: ProgramVisualizeComponentProps) {
  return (
    <>
      <div
        style={{
          paddingTop: isMobile() ? 40 : 60,
          display: isMobile() ? undefined : "flex",
          alignItems: isMobile() ? undefined : "center",
        }}
      >
        <div
          style={{
            fontWeight: 500,
            fontSize: isMobile() ? 24 : 30,
          }}
        >
          {groupedProgram.group_name}
        </div>
        {groupedProgram.group_description !== "" && (
          <div
            style={{
              marginLeft: isMobile() ? undefined : 5,
              fontSize: isMobile() ? 16 : 15,
            }}
          >
            ㅡ {groupedProgram.group_description}
          </div>
        )}
      </div>
      <div style={{ marginTop: isMobile() ? 20 : 40 }}>
        {(() => {
          const components = [];
          const times = isMobile() ? 2 : 4;
          for (let i = 0; i < groupedProgram.programs.length; i += times) {
            components.push(
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  columnGap: isMobile() ? 10 : 20,
                  marginBottom: isMobile() ? 15 : 30,
                }}
                key={i}
              >
                {(() => {
                  const res = [];
                  for (let j = 0; j < times; j++) {
                    const index = i + j;
                    if (index < groupedProgram.programs.length) {
                      res.push(
                        <div style={{ flex: 1 }} key={j}>
                          <ProgramComponent
                            program={groupedProgram.programs[index]}
                            refreshGroupedPrograms={refreshGroupedPrograms}
                          />
                        </div>
                      );
                    } else {
                      res.push(
                        <div
                          style={{
                            flex: 1,
                          }}
                          key={j}
                        />
                      );
                    }
                  }
                  return res;
                })()}
              </div>
            );
          }
          return components;
        })()}
      </div>
    </>
  );
}

function Program() {
  const { groupedPrograms, refreshGroupedPrograms } = useGroupedPrograms();

  const availablePrograms = (programs: ProgramProps[]) => {
    // check program status, if it's all done, then go down
    for (const program of programs) {
      if (program.program_status !== "done") {
        return true;
      }
    }
    return false;
  };

  return (
    <Fragment>
      <GenericBannerComponent bannerType="program" />
      <div
        style={{
          width: isMobile() ? undefined : 1120,
          margin: isMobile() ? 15 : "50px auto",
        }}
      >
        {groupedPrograms
          .sort((a, b) => {
            // sort by one which has program with passed or not
            // if custom date, then apply for custom date,
            // if not custom date, then do not apply for custom date

            if (
              availablePrograms(a.programs) &&
              availablePrograms(b.programs)
            ) {
              return 0;
            }
            if (availablePrograms(a.programs)) {
              return -1;
            }
            return 1;
          })
          .map((groupedProgram, index) => (
            <ProgramVisualizeComponent
              groupedProgram={groupedProgram}
              refreshGroupedPrograms={refreshGroupedPrograms}
              key={index}
            />
          ))}
      </div>
      <div
        style={{
          marginTop: isMobile() ? undefined : 100,
          height: isMobile() ? 130 : 250,
          backgroundColor: "#FAFAFA",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: isMobile() ? 15 : undefined,
          overflow: "hidden",
          position: "relative",
        }}
      >
        <div style={{ flex: isMobile() ? 1 : undefined }}>
          <div style={{ fontWeight: 700, fontSize: isMobile() ? 16 : 30 }}>
            프로그램 오픈 알림
          </div>
          <div
            style={{
              marginTop: isMobile() ? 10 : 30,
              fontSize: isMobile() ? 12 : 15,
            }}
          >
            매달 새로운 프로그램이 오픈됩니다.
            <br />
            필로어스를 친구추가 하시면 가장 먼저 오픈 소식을 받아보실 수
            있습니다.
          </div>
        </div>
        <img
          src="/philous_logo.png"
          alt="philous"
          draggable={false}
          style={{
            height: isMobile() ? 125 : 165,
            opacity: 0.1,
            position: "absolute",
            left: isMobile() ? undefined : 180,
            right: isMobile() ? -25 : undefined,
          }}
        />
        <div
          style={{
            marginLeft: isMobile() ? 25 : 200,
          }}
        >
          <Button
            onClick={() =>
              window.open("https://pf.kakao.com/_xnsACT", "_blank")
            }
            style={{
              color: textColor,
              border: "1px solid black",
              borderRadius: 50,
              width: isMobile() ? 125 : 150,
              height: isMobile() ? 50 : 50,
              fontSize: isMobile() ? 12 : 14,
            }}
          >
            알림받기
          </Button>
        </div>
      </div>
    </Fragment>
  );
}

export default Program;
