import { Hidden } from "@mui/material";
import React, { Fragment, ReactNode, useEffect, useState } from "react";
import { fetchDefaultWithCredential, isMobile } from "../utils";

interface HeaderBannerProps {
  background: string;
  mobile?: string;
  children: ReactNode;
}

interface GenericBannerProps {
  desktop: string;
  mobile: string;
}

export function useGenericBanner(bannerType: string) {
  const [banner, setBanner] = useState<GenericBannerProps>({
    desktop: "",
    mobile: "",
  });
  useEffect(() => {
    fetchDefaultWithCredential(`/banner?type=${bannerType}`, "GET").then(
      (res) => {
        if (!res.ok) {
          return res.json().then(({ error }) => {
            throw new Error(error);
          });
        }
        return res.json().then(setBanner);
      }
    );
  }, [bannerType]);
  return banner;
}

interface GenericBannerComponentProps {
  bannerType: string;
}

export function GenericBannerComponent({
  bannerType,
}: GenericBannerComponentProps) {
  const { desktop, mobile } = useGenericBanner(bannerType);
  return (
    <img
      src={isMobile() ? mobile : desktop}
      alt=""
      style={{
        width: "100%",
        height: "auto",
      }}
    ></img>
  );
}

function HeaderBanner({ background, mobile, children }: HeaderBannerProps) {
  return (
    <Fragment>
      <div className="text-align-center">
        <Hidden smDown>
          <div
            className="p-relative"
            style={{
              backgroundImage: `url(${background})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            {children}
          </div>
        </Hidden>
        <Hidden smUp>
          <div
            className="p-relative"
            style={{
              backgroundImage: `url(${mobile ?? background})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            {children}
          </div>
        </Hidden>
      </div>
    </Fragment>
  );
}

export default HeaderBanner;
